import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Navbar as MTNavbar,
  Collapse,
  IconButton,
  Button,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import logoImage from "/img/logo.png";

export function Navbar({ routes, action = null }) {
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [isSwitchingLanguage, setIsSwitchingLanguage] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleLanguageChange = (language) => {
    setIsSwitchingLanguage(true);
    setTimeout(() => {
      changeLanguage(language);
      setIsSwitchingLanguage(false);
    }, 100);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest(".dropdown")) {
      setIsDropdownOpen(false);
      setActiveSubmenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 960) setOpenNav(false);
    });

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      window.removeEventListener("resize", () => {
        if (window.innerWidth >= 960) setOpenNav(false);
      });
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) setOpenNav(false);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleProductsClick = (e) => {
    if (isMobile) {
      e.preventDefault();
      toggleDropdown();
    } else {
      navigate("/products");
    }
  };
  const navList = (
    <ul
      dir={isRtl ? "rtl" : "ltr"}
      className={`mb-4 mt-2 flex flex-col gap-3 text-inherit lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 ${i18n.language === "ar" ? "font-arabic" : ""}`}
    >
      <li>
        <Link
          to="/"
          className="capitalize text-3xl transition-all duration-300 ease-in-out hover:text-green-500 hover:underline"
        >
          {t("home")}
        </Link>
      </li>
      <li className="relative group dropdown">
        <button
          className="capitalize text-3xl transition-all duration-300 ease-in-out hover:text-green-500 hover:underline"
          onMouseEnter={!isMobile ? toggleDropdown : undefined}
          onClick={handleProductsClick}
          aria-expanded={isDropdownOpen}
          aria-haspopup="true"
        >
          {t("products")}
        </button>

        {(isDropdownOpen || activeSubmenu !== null) && (
          <ul
            dir={isRtl ? "rtl" : "ltr"}
            className={`absolute z-20 top-full mt-2 bg-white text-black shadow-lg rounded-lg w-48 transition-transform duration-200 transform scale-95  ${i18n.language === "ar" ? "font-arabic" : ""}`}
          >
            {[
              {
                name: t("ceiling_system"),
                slug: "ceiling-system",
                subItems: [
                  {
                    id: "01",
                    name: t("mineral_fiber_acoustic"),
                    slug: "mineral-fiber-acoustic",
                  },
                  {
                    id: "02",
                    name: t("soft_fiber_acoustic"),
                    slug: "soft-fiber-acoustic",
                  },
                  { id: "03", name: t("metal"), slug: "metal" },
                  {
                    id: "04",
                    name: t("gypsum_ceiling"),
                    slug: "gypsum-ceiling",
                  },
                  {
                    id: "05",
                    name: t("skynest_wood_wool"),
                    slug: "skynest-wood-wool",
                  },
                  {
                    id: "06",
                    name: t("grid_suspensions"),
                    slug: "grid-suspensions",
                  },
                ],
              },
              {
                name: t("drywall_assemblies"),
                slug: "drywall-assemblies",
                subItems: [
                  { id: "07", name: t("plasterboard"), slug: "plasterboard" },
                  {
                    id: "08",
                    name: t("interior_finishes"),
                    slug: "interior-finishes",
                  },
                ],
              },
              {
                name: t("flooring_solutions"),
                slug: "flooring-solutions",
                subItems: [
                  {
                    id: "09",
                    name: t("flooring_solutions"),
                    slug: "flooring-solutions",
                  },
                ],
              },
              {
                name: t("raised_floor"),
                slug: "raised-floor",
                subItems: [
                  { id: "10", name: t("raised_floor"), slug: "raised-floor" },
                ],
              },
              {
                name: t("wall_solutions"),
                slug: "wall-solutions",
                subItems: [
                  { id: "11", name: t("pvc"), slug: "pvc" },
                  { id: "12", name: t("wpc"), slug: "wpc" },
                  {
                    id: "13",
                    name: t("mdf_acoustic_panel"),
                    slug: "mdf-acoustic-panel",
                  },
                ],
              },
              {
                name: t("handrails"),
                slug: "handrails",
                subItems: [
                  { id: "15", name: t("handrails"), slug: "handrails" },
                ],
              },
            ].map((item, index) => (
              <li
                key={item.name}
                className="group cursor-pointer text-black relative px-4 py-2 hover:text-green-500"
                onMouseEnter={() => setActiveSubmenu(index)}
                onMouseLeave={() => setActiveSubmenu(null)}
              >
                {item.name}
                {activeSubmenu === index && (
                  <ul
                    className={`absolute top-0 mt-0 bg-white text-black shadow-lg rounded-lg w-48 transition-all duration-200 ease-in-out ${
                      isRtl ? "right-full" : "left-full"
                    }`}
                  >
                    {item.subItems.map((subItem) => (
                      <li
                        key={subItem.id}
                        className="px-4 py-2 text-black hover:bg-green-400"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/products/${item.slug}/${subItem.slug}`);
                          setIsDropdownOpen(false);
                          setActiveSubmenu(null);
                        }}
                      >
                        {subItem.name}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </li>
      <li>
        <Link
          to="/showcase"
          className="capitalize text-3xl transition-all duration-300 ease-in-out hover:text-green-500 hover:underline"
        >
          {t("projects")}
        </Link>
      </li>
      <li>
        <Link
          to="/aboutus"
          className="capitalize text-3xl transition-all duration-300 ease-in-out hover:text-green-500 hover:underline"
        >
          {t("About_us")}
        </Link>
      </li>
    </ul>
  );

  return (
    <MTNavbar color="transparent" className="p-3">
      <div className="container mx-auto flex items-center justify-between text-white">
        <Link to="/" className="absolute top-3 left-0">
          <img
            src={logoImage}
            alt="Logo"
            className="relative left-[10px] w-[226px] h-[64px]"
            width="226"
            height="64"
          />
        </Link>
        <div className="hidden lg:flex-grow lg:flex lg:justify-center">
          {navList}
        </div>
        {action &&
          React.cloneElement(action, {
            className: "hidden lg:inline-block",
          })}
        <div
          className={`flex flex-row items-center justify-end absolute right-4 top-[70px]  space-x-2 ${isSwitchingLanguage ? "opacity-0" : "opacity-100"} transition-opacity duration-500 z-10`}
        >
          <Button
            color="blue-gray"
            className="bg-transparent text-base"
            onClick={() => handleLanguageChange("en")}
          >
            EN
          </Button>
          <Button
            color="blue-gray"
            className="bg-transparent text-base"
            onClick={() => handleLanguageChange("ar")}
          >
            العربية
          </Button>
        </div>

        <IconButton
          variant="text"
          size="sm"
          color="white"
          className="lg:hidden ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon strokeWidth={2} className="h-6 w-6" />
          ) : (
            <Bars3Icon strokeWidth={2} className="h-6 w-6" />
          )}
        </IconButton>
      </div>
      <Collapse
        className={`lg:hidden rounded-xl overflow-visible transition duration-500 ease-in-out px-4 pt-2 pb-4 text-blue-gray-900 z-50 fixed w-full mx-auto left-0 ${openNav ? "bg-white" : "h-0"}`}
        open={openNav}
      >
        <div className="container mx-auto flex text-center justify-center flex-col">
          {openNav && navList}
          {action &&
            React.cloneElement(action, {
              className: "mb-4 w-full text-center",
            })}
        </div>
      </Collapse>
    </MTNavbar>
  );
}

Navbar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.node,
};
