import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import { Navbar } from "@/widgets/layout";
import { HelmetProvider } from "react-helmet-async";
// Lazy load pages
const Home = lazy(() => import("@/pages/Home"));
const Products = lazy(() => import("@/pages/Products"));
const Showcase = lazy(() => import("@/pages/Showcase"));
const Aboutus = lazy(() => import("@/pages/Aboutus"));
const NotFoundPage = lazy(() => import("@/pages/404"));
const ProductDetails = lazy(() => import("@/pages/ProductDetails"));
function App() {
  // Simple array for navbar links - matches your original exactly
  const navLinks = [
    { name: "home", path: "/" },
    { name: "Products", path: "/products" },
    { name: "Showcase", path: "/Showcase" },
    { name: "About us", path: "/Aboutus" },
  ];

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <div className="container absolute left-2/4 z-10 mx-auto -translate-x-2/4 p-4">
            <Navbar routes={navLinks} />
          </div>

          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/:categorySlug" element={<Products />} />
              <Route
                path="/products/:categorySlug/:subcategorySlug"
                element={<Products />}
              />
              <Route
                path="/products/:categorySlug/:subcategorySlug/:productSlug"
                element={<ProductDetails />}
              />
              <Route path="/Showcase" element={<Showcase />} />
              <Route path="/Aboutus" element={<Aboutus />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
