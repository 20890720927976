import React from "react";

const year = new Date().getFullYear();

export function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <div className="text-center md:text-left mb-8 md:mb-0">
            <h3 className="text-lg font-bold mb-4">Contact</h3>
            <ul>
              <li>+964-7740705030</li>
              <li>+964-7901766747</li>
              <li>info@babilagency.com</li>
            </ul>
          </div>
          <div className="flex flex-wrap justify-center items-center gap-8 mt-4 md:mt-0">
            <img src="/img/logo.png" alt="Babil" className="w-[200px] h-auto" />
            <img
              src="/img/gerflor.webp"
              alt="Gerflor"
              className="w-[200px] h-auto"
            />
            <img
              src="/img/usgme.webp"
              alt="USG ME"
              className="w-[200px] h-auto"
            />
          </div>
        </div>
        <hr className="my-8 border-gray-700" />
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="text-center text-gray-400 mb-4 md:mb-0">
            Crafted with care by{" "}
            <a
              href="http://siliconsquire.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-700"
            >
              Silicon Squire
            </a>
          </p>
          <p className="text-center text-gray-400">
            &copy; {year} Babel Trading
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
